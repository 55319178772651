import React from "react";

const PageHeader = ({ children, page }) => {
  return (
    <div className="common__header">
      {
        <div className={`common__bg ${page}__bg`}>
          <h2>{children}</h2>
        </div>
      }
    </div>
  );
};

export default PageHeader;
