import * as React from "react"

import LocationImg from "../images/location.png";
import * as styles from "../styles/sass/layout/_aboutGoGoIsland.module.scss"

const AboutLocation = () => {
    return(
        <>
        <div className="common__layout">
            <h2 className="common__title">興居島（ごごしま）について</h2>
        </div>
        <div className={styles.aboutGogoisland}>
            <p>愛媛県松山市高浜港（たかはまこう）の西２㎞、フェリーで約１０分～１５分のところに位置する小さな島です。<br />（ちなみに、こちらのフェリー、車やバイクも載せることができます。）</p>
            <p>高浜港からも島は見えてはいますが、橋などはありません。</p>
            <img src={LocationImg} alt="興居島について" />
            <p>島の中には歴史ある寺社仏閣をはじめ、国の無形民俗文化財、愛媛県の無形民族文化財などに認定されている船踊り（ふなおどり）があり、１０月のお祭りには多くの方が見物に来られます。</p>
            <p>レンタルサイクルで島めぐりもできますし、夏には海水浴もできますよ！</p>
            <p>興居島は海と、山と、ゆっくりとした時間に包まれています。</p>
            {/* <h3>船踊りとは</h3>
            <p>瀬戸内海で活躍した水軍が戦いを終えて帰った際、迎えてくれた島民の方に向けて戦いの様子を船の上で身振り手振りで伝えたのが始まりとされています。</p>
            <p>衣装やメイクなどにもとてもこだわっている、見ごたえたっぷりの踊りです。<br/>ちなみに、うちの長女が２歳の時、踊り手の方を間近で見て泣きました。（笑）</p> */}
        </div>
        </>
    )
}

export default AboutLocation;