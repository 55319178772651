import * as React from "react"
import AboutInstagram from "../components/aboutInstagram"
import AboutLocation from "../components/aboutLocation"
import Footer from "../components/footer"
import Header from "../components/header"
import PageHeader from "../components/pageHeader"

const Location = () => {
    return(
        <React.Fragment>
            <Header />
            <main>
                <PageHeader page="location">LOCATION</PageHeader>
                <AboutLocation />
                <AboutInstagram />
            </main>
            <Footer />
        </React.Fragment>
    )
}

export default Location;

export const Head = () => <title>GoGo Island | LOCATION</title>